window.onscroll = function() {
  scrollFunction();
};

function scrollFunction() {
    const navi = document.querySelector('#navbar');
    if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 180) {
      navi.classList.add("shrink");
    } else {
      navi.classList.remove("shrink");
    }
}

const navLinkELs = document.querySelectorAll('.nav-link');
const mobileMenuEL = document.querySelector('#navbar-toggler');

navLinkELs.forEach(navLinkEL => {
  navLinkEL.addEventListener('click', () => {
    if (window.innerWidth < 992) {
      mobileMenuEL.click();
    }
  });
});
