let requireAll = (req) => { return req.keys().map(req); };
console.log('webpack running');

// icons
requireAll(require.context('./icons/', true, /\.svg$/));

// twig
// RegEx: load only Twig-files NOT starting with an underscore (since those are partials)
requireAll(require.context('./twig', true, /(?:(?:(?:[/]?)(?:[^/]+\/)+)([^_]+\.twig))/));

// JS
window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');
// import './js/swiper.js'; // just uncomment if you need swiper.js
import './js/forplaner.js';

// SCSS
import './scss/main.scss';
